import { Box, CrossIcon, Modal, ModalContent, Stack, Typography } from '@lendoab/aphrodite';
import TimeLeftUCWindow from 'APP/components/Application/View/Business/ApplicationWrapper/TimeLeftUCWindow';
import ConsentFormAphrodite from 'APP/components/Consent/Forms/ConsentFormAphrodite';
import ContactInfoForm from 'APP/components/ContactInfo/ContactInfoForm';
import ApplicantHelpers from 'APP/helpers/ApplicantHelper';
import ApplicationHelper from 'APP/helpers/ApplicationHelper';
import ClipboardHelper from 'APP/helpers/ClipboardHelper';
import CompanyHelper from 'APP/helpers/CompanyHelper';
import DataDisplayHelper from 'APP/helpers/DataDisplayHelper';
import PropTypes from 'prop-types';
import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { reduxForm } from 'redux-form';
import { Button, Header, Segment, Table } from 'semantic-ui-react';

import ApplicantCardHeader from '../../../../../../../pages/PartnerApplication/View/ApplicantCard/Header';
import NullableBooleanField from '../../NullableBooleanField';

function ApplicantInformation(props) {
    const { applicant, company, boxed, admin, application, reFetchApplication } = props;
    const [showingSection, setShowingSection] = useState('creditData');
    const applicantUserData = applicant.user;
    const [isCallButtonDisabled, setIsCallButtonDisabled] = useState(false);

    return (
        <Segment.Group className={boxed ? '' : 'basic'} style={!admin ? { padding: '25px' } : { padding: '0px' }}>
            <ApplicantCardHeader
                name={ApplicantHelpers.fullName(applicant)}
                role={applicant.role}
                willingToBeGuarantor={applicant.willing_to_be_guarantor}
                beneficialOwnerLabel={props.beneficialOwnerLabel}
                boxed={boxed}
                admin={admin}
                type={company.type}
                assumedBeneficialOwner={props.assumedBeneficialOwner}
                applicant={applicant}
                ssn={applicant.ssn}
                application={application}
                setShowingSection={setShowingSection}
                showingSection={showingSection}
            />

            {admin && showingSection === 'editContactInfo' && (
                <ContactInfoForm
                    admin={admin}
                    applicant={applicant}
                    applicationType={application.type}
                    reFetchApplication={reFetchApplication}
                    editContactInfo={true}
                />
            )}

            {admin && showingSection === 'consent' && (
                <Modal
                    style={{ zIndex: 100 }}
                    open={admin && showingSection === 'consent'}
                    onClose={() => setShowingSection('creditData')}
                >
                    <ModalContent padding="none" style={{ maxWidth: 600, minWidth: 400 }}>
                        <Box display="flex" justifyContent="space-between" paddingTop="2xl" paddingX="2xl">
                            <Stack space="base">
                                <Typography.Title level={3}>Consent settings</Typography.Title>
                                <Typography.Body>
                                    Consent preferences related to newsletters, application notifications and machine
                                    learning can be changed here.
                                </Typography.Body>
                            </Stack>
                            <Button size="small" borderless square onClick={() => setShowingSection('creditData')}>
                                <CrossIcon />
                            </Button>
                        </Box>
                        <ConsentFormAphrodite applicant={applicant} applicationId={application.id} />
                    </ModalContent>
                </Modal>
            )}

            {showingSection === 'creditData' && (
                <Fragment>
                    <Table structured compact basic="very">
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell colSpan="3">
                                    <Header as="h4" sub content="SSN" />
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                        }}
                                    >
                                        {admin && applicantUserData ? (
                                            <Link to={`/customer/${applicant.user.user_id}/view`}>
                                                {ApplicationHelper.formatSSN(applicant.ssn)}
                                            </Link>
                                        ) : (
                                            <span>{ApplicationHelper.formatSSN(applicant.ssn)}</span>
                                        )}
                                        <div>
                                            <Button
                                                type="button"
                                                icon="copy"
                                                size="mini"
                                                id="copy-ssn"
                                                onClick={() => ClipboardHelper.copy(applicant.ssn)}
                                                style={{ padding: '8px', height: '25px' }}
                                            />

                                            <Button
                                                type="button"
                                                icon="copy"
                                                content="10"
                                                size="mini"
                                                id="copy-ssn"
                                                onClick={() => ClipboardHelper.copy(applicant.ssn.substring(2))}
                                                style={{ padding: '8px', height: '25px' }}
                                            />
                                        </div>
                                    </div>
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell colSpan="1">
                                    <Header as="h4" sub content="Phone" />
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <span>{applicant.phone}</span>
                                        <div>
                                            <Button
                                                type="button"
                                                icon="copy"
                                                size="mini"
                                                style={{ padding: '8px', height: '25px' }}
                                                onClick={() => {
                                                    ClipboardHelper.copy(applicant.phone);
                                                }}
                                            />
                                            <Button
                                                type="button"
                                                icon="phone"
                                                size="mini"
                                                style={{ padding: '8px', height: '25px' }}
                                                disabled={isCallButtonDisabled}
                                                onClick={async () => {
                                                    setIsCallButtonDisabled(true);
                                                    await ApplicantHelpers.clickToCall(
                                                        applicant.phone,
                                                        application.id,
                                                        application.revision
                                                    );
                                                    setIsCallButtonDisabled(false);
                                                }}
                                            />
                                        </div>
                                    </div>
                                </Table.Cell>
                                <Table.Cell colSpan="2">
                                    <Header as="h4" sub content="Email" />
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <span style={{ wordBreak: 'break-all' }}>
                                            {applicant.email === 'noemail@lendo.se' ? 'Incorrect' : applicant.email}
                                        </span>
                                        <Button
                                            type="button"
                                            icon="copy"
                                            size="mini"
                                            style={{ padding: '8px', height: '25px' }}
                                            onClick={() => {
                                                ClipboardHelper.copy(applicant.email);
                                            }}
                                        />
                                    </div>
                                </Table.Cell>
                            </Table.Row>
                            {CompanyHelper.isEN(props.company) && (
                                <Table.Row>
                                    <Table.Cell>
                                        <Header as="h4" sub content="Blanco loans" />
                                        <span>{DataDisplayHelper.currency(applicant.blanco_loans, 'kr') || 'N/A'}</span>
                                    </Table.Cell>
                                    <Table.Cell>
                                        <Header as="h4" sub content="Installment Loans" />
                                        <span>
                                            {DataDisplayHelper.currency(applicant.installment_loans, 'kr') || 'N/A'}
                                        </span>
                                    </Table.Cell>
                                    <Table.Cell>
                                        <Header as="h4" sub content="House loans" />
                                        <span>{DataDisplayHelper.currency(applicant.house_loans, 'kr') || 'N/A'}</span>
                                    </Table.Cell>
                                </Table.Row>
                            )}
                            <Table.Row>
                                <Table.Cell colSpan={CompanyHelper.isEN(props.company) ? 1 : 3}>
                                    <Header as="h4" sub content="PEP" />
                                    <NullableBooleanField value={applicant.is_politically_exposed} />
                                </Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>
                    <TimeLeftUCWindow application={application} />
                </Fragment>
            )}
        </Segment.Group>
    );
}

ApplicantInformation.propTypes = {
    applicant: PropTypes.shape({
        id: PropTypes.string.isRequired,
    }).isRequired,
    company: PropTypes.shape({
        type: PropTypes.oneOf(CompanyHelper.values).isRequired,
    }).isRequired,
    beneficialOwnerLabel: PropTypes.string,
    boxed: PropTypes.bool,
    admin: PropTypes.bool,
    assumedBeneficialOwner: PropTypes.bool,
};

ApplicantInformation.defaultProps = {
    boxed: true,
    admin: false,
    beneficialOwnerLabel: null,
    assumedBeneficialOwner: false,
};

const ApplicantInformationForm = reduxForm()(ApplicantInformation);
export default connect((state, ownProps) => ({
    form: `ApplicantForm.${ownProps.applicant.id}`,
    initialValues: {
        phone: ownProps.applicant.phone,
        email: ownProps.applicant.email,
    },
}))(ApplicantInformationForm);
