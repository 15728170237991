import Field, { Validation } from '@lendoab/lendo-redux-form-helpers';
import PropTypes from 'prop-types';
import React from 'react';
import { formValues } from 'redux-form';
import { Table } from 'semantic-ui-react';

import ComplementsHelper from '../../../../helpers/ComplementsHelper';

const ComplementsBusiness = (props) => {
    const getOnChange = (key) =>
        ['one_guarantor', 'two_guarantors'].includes(key) ? props.toggleGuarantorComplement(key) : null;

    return (
        <Table basic="very" collapsing celled selectable>
            <Table.Body>
                {Object.entries(ComplementsHelper.getComplements(ComplementsHelper.types.BUSINESS)).map(
                    ([key, value]) => {
                        let settingsComponent = (
                            <Field
                                component="checkbox"
                                toggle
                                name={`complements.${key}`}
                                onChange={getOnChange(key)}
                            />
                        );

                        if (key === 'dynamic_guarantor') {
                            settingsComponent = (
                                <Field
                                    component="input"
                                    name="complements.dynamic_guarantor"
                                    type="number"
                                    parse="int"
                                    placeholder="%"
                                    validate={[Validation.minValue(1), Validation.maxValue(100)]}
                                />
                            );
                        }

                        return (
                            <Table.Row key={key}>
                                <Table.Cell>{value}</Table.Cell>
                                <Table.Cell>{settingsComponent}</Table.Cell>
                            </Table.Row>
                        );
                    }
                )}
            </Table.Body>
        </Table>
    );
};

ComplementsBusiness.propTypes = {
    mainApplicant: PropTypes.object.isRequired,
    toggleGuarantorComplement: PropTypes.func.isRequired,
};

ComplementsBusiness.defaultProps = {};

export default formValues('complements')(ComplementsBusiness);
